export var BrokerName;
(function (BrokerName) {
    BrokerName[BrokerName["Argenta"] = 0] = "Argenta";
    BrokerName[BrokerName["Belfius"] = 1] = "Belfius";
    BrokerName[BrokerName["BinckBank"] = 2] = "BinckBank";
    BrokerName[BrokerName["BNPParibasFortis"] = 3] = "BNPParibasFortis";
    BrokerName[BrokerName["Bolero"] = 4] = "Bolero";
    BrokerName[BrokerName["DEGIRO"] = 5] = "DEGIRO";
    BrokerName[BrokerName["DeutscheBank"] = 6] = "DeutscheBank";
    BrokerName[BrokerName["Keytrade"] = 7] = "Keytrade";
    BrokerName[BrokerName["LYNX"] = 8] = "LYNX";
})(BrokerName || (BrokerName = {}));
export const brokerNames = [
    BrokerName.Argenta,
    BrokerName.Belfius,
    BrokerName.BinckBank,
    BrokerName.BNPParibasFortis,
    BrokerName.Bolero,
    BrokerName.DEGIRO,
    BrokerName.DeutscheBank,
    BrokerName.Keytrade,
    BrokerName.LYNX,
];
export class Broker {
}

export const global = JSON.parse(`{
    "fee.standard": "standard",
    "fee.inactivity": "inactivity",
    "fee.opening": "opening",
    "fee.withdrawal": "withdrawal",
    "language.dutch": "Nederlands",
    "language.english": "English",
    "language.french": "Francais"
}`);
export const english = JSON.parse(`{
    "ui.day": "day",
    "ui.language": "language",
    "ui.currency": "currency",
    "ui.broker": "broker",
    "ui.brokers": "brokers",
    "ui.frequency": "frequency",
    "ui.exchange": "exchange",
    "ui.security": "security",
    "ui.totalvalue": "total value",
    "ui.quantity": "quantity",
    "ui.remove": "remove",
    "ui.transaction": "transaction",
    "ui.transactions": "transactions",
    "ui.action": "action",
    "ui.actions": "actions",
    "ui.newaction": "new action",
    "ui.newtransaction": "new transaction",
    "ui.removeaction": "remove action",
    "ui.removetransaction": "remove transaction",
    "ui.type": "type",
    "ui.totalfees": "total fees",
    "ui.totalfee": "total fee",
    "fee.type.standard": "standard fee",
    "fee.type.opening": "opening fee",
    "fee.type.inactivity": "inactivity fee",
    "fee.type.withdrawal": "withdrawal fee",
    "fee.type.transaction": "transaction fee",
    "security.share": "share",
    "security.etf": "ETF",
    "security.option": "option",
    "security.bond": "bond",
    "transaction.buy": "buy",
    "transaction.sell": "sell",
    "currency.usd.short": "USD",
    "currency.gbp.short": "GBP",
    "currency.eur.short": "EUR",
    "broker.argenta": "Argenta",
    "broker.belfius": "Belfius",
    "broker.binckbank": "Binck Bank",
    "broker.bnpparibasfortis": "BNP Paribas Fortis",
    "broker.bolero": "Bolero",
    "broker.degiro": "DEGIRO",
    "broker.deutschebank": "Deutsche Bank",
    "broker.keytrade": "Keytrade",
    "broker.lynx": "LYNX",
    "exchange.nyse": "NYSE",
    "exchange.nasdaq": "NASDAQ",
    "exchange.japanexchangegroup": "Japan Exchange Group",
    "exchange.londonstockexchange": "London Stock Exchange",
    "exchange.shanghaistockexchange": "Shanghai Stock Exchange",
    "exchange.hongkongstockexchange": "Hong Kong Stock Exchange",
    "exchange.euronextamsterdam": "Euronext Amsterdam",
    "exchange.euronextbrussels": "Euronext Brussels",
    "exchange.euronextdublin": "Euronext Dublin",
    "exchange.euronextlisbon": "Euronext Lisbon",
    "exchange.euronextoslo": "Euronext Oslo",
    "exchange.euronextparis": "Euronext Paris",
    "exchange.torontostockexchange": "Toronto Stock Exchange",
    "exchange.shenzhenstockexchange": "Shenzhen Stock Exchange",
    "exchange.bombaystockexchange": "Bombay Stock Exchange",
    "exchange.indianationalstockexchange": "India National Stock Exchange",
    "exchange.deutscheborse": "Deutsche Borse",
    "exchange.sixswissexchange": "SIX Swiss Exchange",
    "exchange.koreaexchange": "Korea Exchange",
    "action.deposit": "deposit",
    "action.transaction": "transaction",
    "action.withdrawal": "withdrawal",
    "action.openaccount": "open account",
    "action.paiddividend": "paid dividend",
    "fee.description.interval.minimum": "Min fee of {0} if value is between {1} and {2}",
    "fee.description.interval.minimumWithNoClose": "Min fee of {0} if value above {1}",
    "fee.description.percentage": "{0} * {1} = {2}",
    "fee.description.maximum": "Max fee of {0} if value is between {1} and {2}",
    "fee.description.interval.calculation": "{0} fee per {1} if value is between {2} and {3}",
    "fee.description.interval.calculationWithStandard": "{0} standard fee and {1} fee per {2} if value is above {3}"
}`);
export const dutch = {};

import { Broker, BrokerName } from "./Broker";
import { convertCurrency, CurrencyType } from "../Money";
import { Fee } from "../Fee";
import { calculateIntervalFees, Exchange, SecurityType } from "../action/Transaction";
import { ActionType } from "../action/ActionType";
import { formatMoney, formatPercentage } from "../../i18n/language";
function getDomesticMarketsFeeIntervals(transaction) {
    return [
        {
            openFrom: [CurrencyType.EUR, 0],
            closedTo: [CurrencyType.EUR, 250000],
            minimumFee: new Fee(transaction, [CurrencyType.EUR, 750], "fee.type.transaction", "fee.description.interval.minimum", [
                locale => formatMoney([CurrencyType.EUR, 750], locale),
                locale => formatMoney([CurrencyType.EUR, 0], locale),
                locale => formatMoney([CurrencyType.EUR, 250000], locale),
            ]),
        },
        {
            openFrom: [CurrencyType.EUR, 250000],
            closedTo: [CurrencyType.EUR, 7000000],
            minimumFee: new Fee(transaction, [CurrencyType.EUR, 1500], "fee.type.transaction", "fee.description.interval.minimum", [
                locale => formatMoney([CurrencyType.EUR, 1500], locale),
                locale => formatMoney([CurrencyType.EUR, 250000], locale),
                locale => formatMoney([CurrencyType.EUR, 7000000], locale),
            ]),
            maximumFee: new Fee(transaction, [CurrencyType.EUR, 5000], "fee.type.transaction", "bolero.interval1.maximumFee", [
                locale => formatMoney([CurrencyType.EUR, 5000], locale),
                locale => formatMoney([CurrencyType.EUR, 250000], locale),
                locale => formatMoney([CurrencyType.EUR, 7000000], locale),
            ]),
            calculateFee: value => {
                // Fee = 15 EUR per 10 000 EUR
                const eurValue = convertCurrency(value, CurrencyType.EUR);
                const n = Math.floor(eurValue / 1000000);
                return new Fee(transaction, [CurrencyType.EUR, n * 1500], "fee.type.transaction", "fee.description.interval.calculation", [
                    locale => formatMoney([CurrencyType.EUR, 1500], locale),
                    locale => formatMoney([CurrencyType.EUR, 1000000], locale),
                    locale => formatMoney([CurrencyType.EUR, 250000], locale),
                    locale => formatMoney([CurrencyType.EUR, 7000000], locale),
                ]);
            },
        },
        {
            openFrom: [CurrencyType.EUR, 7000000],
            calculateFee: value => {
                // Fee = 50 EUR + 15 EUR per extra 10 000 EUR
                const eurValue = convertCurrency(value, CurrencyType.EUR);
                const n = Math.floor((eurValue - 7000000) / 1000000);
                return new Fee(transaction, [CurrencyType.EUR, 5000 + n * 1500], "fee.type.transaction", "fee.description.interval.calculationWithStandard", [
                    locale => formatMoney([CurrencyType.EUR, 1500], locale),
                    locale => formatMoney([CurrencyType.EUR, 5000], locale),
                    locale => formatMoney([CurrencyType.EUR, 1000000], locale),
                    locale => formatMoney([CurrencyType.EUR, 7000000], locale),
                ]);
            },
        },
    ];
}
function getAmericanMarketsFeeIntervals(transaction) {
    return [
        {
            openFrom: [CurrencyType.EUR, 0],
            closedTo: [CurrencyType.EUR, 250000],
            minimumFee: new Fee(transaction, [CurrencyType.USD, 1500], "fee.type.transaction", "fee.description.interval.minimum", [
                locale => formatMoney([CurrencyType.USD, 1500], locale),
                locale => formatMoney([CurrencyType.EUR, 0], locale),
                locale => formatMoney([CurrencyType.EUR, 250000], locale),
            ]),
        },
        {
            openFrom: [CurrencyType.EUR, 250000],
            closedTo: [CurrencyType.EUR, 7000000],
            minimumFee: new Fee(transaction, [CurrencyType.USD, 2000], "fee.type.transaction", "fee.description.interval.minimum", [
                locale => formatMoney([CurrencyType.USD, 2000], locale),
                locale => formatMoney([CurrencyType.EUR, 0], locale),
                locale => formatMoney([CurrencyType.EUR, 250000], locale),
            ]),
            maximumFee: new Fee(transaction, [CurrencyType.USD, 5000], "fee.type.transaction", "fee.description.maximum", [
                locale => formatMoney([CurrencyType.USD, 2000], locale),
                locale => formatMoney([CurrencyType.EUR, 0], locale),
                locale => formatMoney([CurrencyType.EUR, 250000], locale),
            ]),
            calculateFee: value => {
                // Fee = 20 USD per 10 000 USD
                const usdValue = convertCurrency(value, CurrencyType.USD);
                const n = Math.floor(usdValue / 1000000);
                return new Fee(transaction, [CurrencyType.USD, n * 2000], "fee.type.transaction", "fee.description.interval.calculation", [
                    locale => formatMoney([CurrencyType.USD, 2000], locale),
                    locale => formatMoney([CurrencyType.USD, 1000000], locale),
                    locale => formatMoney([CurrencyType.EUR, 250000], locale),
                    locale => formatMoney([CurrencyType.EUR, 7000000], locale),
                ]);
            },
        },
        {
            openFrom: [CurrencyType.EUR, 7000000],
            calculateFee: value => {
                // Fee = 50 USD + 20 USD per extra 10 000 USD
                const usdValue = convertCurrency(value, CurrencyType.USD);
                const n = Math.floor((usdValue - convertCurrency([CurrencyType.EUR, 70000], CurrencyType.USD)) / 1000000);
                return new Fee(transaction, [CurrencyType.USD, n * 5000 + n * 1500], "fee.type.transaction", "fee.description.interval.calculation", [
                    locale => formatMoney([CurrencyType.USD, 5000], locale),
                    locale => formatMoney([CurrencyType.USD, 2000], locale),
                    locale => formatMoney([CurrencyType.USD, 1000000], locale),
                    locale => formatMoney([CurrencyType.EUR, 7000000], locale),
                ]);
            },
        },
    ];
}
function getCanadianMarketsFeeIntervals(transaction) {
    return [
        {
            openFrom: [CurrencyType.EUR, 0],
            closedTo: [CurrencyType.EUR, 250000],
            minimumFee: new Fee(transaction, [CurrencyType.EUR, 1500], "fee.type.transaction", "fee.description.interval.minimum", [
                locale => formatMoney([CurrencyType.EUR, 1500], locale),
                locale => formatMoney([CurrencyType.EUR, 0], locale),
                locale => formatMoney([CurrencyType.EUR, 250000], locale),
            ]),
        },
        {
            openFrom: [CurrencyType.EUR, 250000],
            minimumFee: new Fee(transaction, [CurrencyType.EUR, 2000], "fee.type.transaction", "fee.description.interval.minimumWithNoClose", [
                locale => formatMoney([CurrencyType.EUR, 2000], locale),
                locale => formatMoney([CurrencyType.EUR, 250000], locale),
            ]),
            calculateFee: value => new Fee(transaction, value, "fee.type.transaction", "fee.description.percentage", [
                locale => formatPercentage(0.005, locale),
                locale => formatMoney(value, locale),
                locale => formatMoney([value[0], value[1] * 0.005], locale),
            ]),
        },
    ];
}
function getEuropeanMarketsFeeIntervals(transaction) {
    return [
        {
            openFrom: [CurrencyType.EUR, 0],
            closedTo: [CurrencyType.EUR, 250000],
            minimumFee: new Fee(transaction, [CurrencyType.EUR, 1500], "fee.type.transaction", "fee.description.interval.minimum", [
                locale => formatMoney([CurrencyType.EUR, 1500], locale),
                locale => formatMoney([CurrencyType.EUR, 0], locale),
                locale => formatMoney([CurrencyType.EUR, 250000], locale),
            ]),
        },
        {
            openFrom: [CurrencyType.EUR, 250000],
            minimumFee: new Fee(transaction, [CurrencyType.EUR, 3000], "fee.type.transaction", "fee.description.interval.minimumWithNoClose", [
                locale => formatMoney([CurrencyType.EUR, 1500], locale),
                locale => formatMoney([CurrencyType.EUR, 250000], locale),
            ]),
            calculateFee: value => new Fee(transaction, [value[0], value[1] * 0.005], "fee.type.transaction", "fee.description.percentage", [
                locale => formatPercentage(0.005, locale),
                locale => formatMoney(value, locale),
                locale => formatMoney([value[0], value[1] * 0.005], locale),
            ]),
        },
    ];
}
function getOfflineMarketsFeeIntervals(transaction) {
    return [
        {
            openFrom: [CurrencyType.EUR, 0],
            minimumFee: new Fee(transaction, [CurrencyType.EUR, 7500], "fee.type.transaction", "fee.description.interval.minimumWithNoClose", [
                locale => formatMoney([CurrencyType.EUR, 1500], locale),
                locale => formatMoney([CurrencyType.EUR, 0], locale),
            ]),
            calculateFee: value => new Fee(transaction, [value[0], value[1] * 0.01], "fee.type.transaction", "fee.description.percentage", [
                locale => formatPercentage(0.01, locale),
                locale => formatMoney(value, locale),
                locale => formatMoney([value[0], value[1] * 0.01], locale),
            ]),
        },
    ];
}
export class Bolero extends Broker {
    constructor() {
        super(...arguments);
        this.name = BrokerName.Bolero;
    }
    calculateFees(actions) {
        const map = new Map();
        for (const action of actions) {
            switch (action.type) {
                case ActionType.Transaction:
                    map.set(action, Bolero.getTransactionFees(action));
                    break;
            }
        }
        return map;
    }
    static getTransactionFees(transaction) {
        switch (transaction.security.type) {
            case SecurityType.Share:
            case SecurityType.ETF:
                return Bolero.calculateEquityFees(transaction);
        }
        return [];
    }
    static calculateEquityFees(transaction) {
        switch (transaction.exchange) {
            case Exchange.EuronextBrussels:
            case Exchange.EuronextAmsterdam:
            case Exchange.EuronextParis:
            case Exchange.EuronextLisbon:
                return calculateIntervalFees(transaction, getDomesticMarketsFeeIntervals(transaction));
            case Exchange.NYSE:
            case Exchange.Nasdaq:
                return calculateIntervalFees(transaction, getAmericanMarketsFeeIntervals(transaction));
            case Exchange.TorontoStockExchange:
                return calculateIntervalFees(transaction, getCanadianMarketsFeeIntervals(transaction));
            case Exchange.DeutscheBorse:
            case Exchange.SIXSwissExchange:
            case Exchange.LondonStockExchange:
                // TODO: add more European markets
                return calculateIntervalFees(transaction, getEuropeanMarketsFeeIntervals(transaction));
            default:
                return calculateIntervalFees(transaction, getOfflineMarketsFeeIntervals(transaction));
        }
    }
}

<script lang="ts">
    import type { Security, Transaction } from "../calc/action/Transaction";
    import { SecurityType } from "../calc/action/Transaction";
    import { actions, lang } from "../stores";
    import { exchanges } from "../calc/action/Transaction";
    import { transactionTypes, securityTypes } from "../calc/action/Transaction";
    import { currencyTypes } from "../calc/Money";
    import { getCurrency, getExchange, getMessage, getSecurityType, getTransactionType } from "../i18n/language";

    export let action: Transaction;
    export let id: number;

    let totalValueNumber = action.value[1] / 100;
    
    const securitiesDataByType: { [securityType: SecurityType]: Security } = {
        [SecurityType.Share]: {
            type: SecurityType.Share,
        },
        [SecurityType.ETF]: {
            type: SecurityType.ETF,
        },
        [SecurityType.Option]: {
            type: SecurityType.Option,
        },
        [SecurityType.Bond]: {
            type: SecurityType.Bond,
            maturity: Date.now() + 31536000000, // matures in 1 year
        },
    };

    function change(newProperties: Partial<Transaction>) {
        actions.change(action, newProperties);
    }
</script>

<div class="input transaction-type">
    <label for="transaction-type-{id}">{getMessage($lang, "ui.type")}</label>
    <select id="transaction-type-{id}" value={action.transactionType} on:change={e => change({ transactionType: Number(e.target.value) })}>
        {#each transactionTypes as transactionType}
            <option value={transactionType}>{getTransactionType($lang, transactionType)}</option>
        {/each}
    </select>
</div>

<div class="input security-type">
    <label for="security-type-{id}">{getMessage($lang, "ui.security")}</label>
    <select id="security-type-{id}" value={action.security.type} on:change={e => change({ security: securitiesDataByType[e.target.value] })}>
        {#each securityTypes as securityType}
            <option value={securityType}>{getSecurityType($lang, securityType)}</option>
        {/each}
    </select>
</div>

<div class="input exchange">
    <label for="exchange-{id}">{getMessage($lang, "ui.exchange")}</label>
    <select id="exchange-{id}" value={action.exchange} on:change={e => change({ exchange: Number(e.target.value) })}>
        {#each exchanges as exchange}
            <option value={exchange}>{getExchange($lang, exchange)}</option>
        {/each}
    </select>
</div>

<div class="input total-value">
    <label for="total-value-{id}">{getMessage($lang, "ui.totalvalue")}</label>
    <input type="number" id="total-value-{id}" placeholder="0.00" value={totalValueNumber} on:input={e => change({ value: [action.value[0], Number(e.target.value) * 100] })} />
</div>

<div class="input quantity">
    <label for="quantity-{id}">{getMessage($lang, "ui.quantity")}</label>
    <input type="number" id="quantity-{id}" placeholder="0" value={action.quantity} on:input={e => change({ quantity: Number(e.target.value) })} />
</div>

<div class="input currency">
    <label for="currency-{id}">{getMessage($lang, "ui.currency")}</label>
    <select id="currency-{id}" value={action.value[0]} on:change={e => change({ currency: [Number(e.target.value), action.value[1]] })}>
        {#each currencyTypes as currency}
            <option value={currency}>{getCurrency($lang, currency)}</option>
        {/each}
    </select>
</div>

import { calculateIntervalFees, Exchange, SecurityType } from "../action/Transaction";
import { CurrencyType } from "../Money";
import { Fee } from "../Fee";
import { formatMoney, formatPercentage } from "../../i18n/language";
import { Broker, BrokerName } from "./Broker";
import { ActionType } from "../action/ActionType";
function getGroup1FeeIntervals(transaction) {
    return [
        {
            openFrom: [CurrencyType.EUR, 0],
            minimumFee: new Fee(transaction, [CurrencyType.EUR, 2000], "fee.type.transaction", "fee.description.interval.minimumWithNoClose", [
                locale => formatMoney([CurrencyType.EUR, 2000], locale),
                locale => formatMoney([CurrencyType.EUR, 0], locale),
                locale => formatMoney([CurrencyType.EUR, 1000000], locale),
            ]),
            calculateFee: value => new Fee(transaction, [value[0], value[1] * 0.005], "fee.type.transaction", "fee.description.percentage", [
                locale => formatPercentage(0.005, locale),
                locale => formatMoney(value, locale),
                locale => formatMoney([CurrencyType.EUR, value[1] * 0.005], locale),
            ]),
        },
    ];
}
function getGroup2FeeIntervals(transaction) {
    return [
        {
            openFrom: [CurrencyType.EUR, 0],
            minimumFee: new Fee(transaction, [CurrencyType.EUR, 4000], "fee.type.transaction", "fee.description.interval.minimumWithNoClose", [
                locale => formatMoney([CurrencyType.EUR, 2000], locale),
                locale => formatMoney([CurrencyType.EUR, 0], locale),
                locale => formatMoney([CurrencyType.EUR, 1000000], locale),
            ]),
            calculateFee: value => new Fee(transaction, [value[0], value[1] * 0.005], "fee.type.transaction", "fee.description.percentage", [
                locale => formatPercentage(0.005, locale),
                locale => formatMoney(value, locale),
                locale => formatMoney([CurrencyType.EUR, value[1] * 0.005], locale),
            ]),
        },
    ];
}
function getGroup3FeeIntervals(transaction) {
    return [
        {
            openFrom: [CurrencyType.EUR, 0],
            minimumFee: new Fee(transaction, [CurrencyType.EUR, 4000], "fee.type.transaction", "fee.description.interval.minimumWithNoClose", [
                locale => formatMoney([CurrencyType.EUR, 2000], locale),
                locale => formatMoney([CurrencyType.EUR, 0], locale),
                locale => formatMoney([CurrencyType.EUR, 1000000], locale),
            ]),
            calculateFee: value => new Fee(transaction, [value[0], value[1] * 0.006], "fee.type.transaction", "fee.description.percentage", [
                locale => formatPercentage(0.006, locale),
                locale => formatMoney(value, locale),
                locale => formatMoney([CurrencyType.EUR, value[1] * 0.006], locale),
            ]),
        },
    ];
}
export class Argenta extends Broker {
    constructor() {
        super(...arguments);
        this.name = BrokerName.Argenta;
    }
    calculateFees(actions) {
        const map = new Map();
        for (const action of actions) {
            switch (action.type) {
                case ActionType.Transaction:
                    map.set(action, Argenta.getTransactionFees(action));
                    break;
            }
        }
        return map;
    }
    static getTransactionFees(transaction) {
        switch (transaction.security.type) {
            case SecurityType.Share:
            case SecurityType.ETF:
                return Argenta.calculateEquityFees(transaction);
        }
        return [];
    }
    static calculateEquityFees(transaction) {
        switch (transaction.exchange) {
            case Exchange.EuronextParis:
            case Exchange.EuronextBrussels:
            case Exchange.EuronextAmsterdam:
                return calculateIntervalFees(transaction, getGroup1FeeIntervals(transaction));
            case Exchange.EuronextLisbon:
            case Exchange.NYSE:
            case Exchange.Nasdaq:
            case Exchange.DeutscheBorse:
            case Exchange.LondonStockExchange:
            case Exchange.LuxembourgStockExchange:
            case Exchange.BorsaItaliana:
            case Exchange.TorontoStockExchange:
                return calculateIntervalFees(transaction, getGroup2FeeIntervals(transaction));
            default:
                return calculateIntervalFees(transaction, getGroup3FeeIntervals(transaction));
        }
    }
}

import { dutch, english } from "./languages";
import { CurrencyType } from "../calc/Money";
import { getCurrencySymbol } from "../util";
import { BrokerName } from "../calc/broker/Broker";
import { Exchange, SecurityType, TransactionType } from "../calc/action/Transaction";
import { ActionType } from "../calc/action/ActionType";
export var Language;
(function (Language) {
    Language[Language["English"] = 0] = "English";
    Language[Language["Nederlands"] = 1] = "Nederlands";
})(Language || (Language = {}));
export const languages = [
    Language.English,
    Language.Nederlands,
];
export function getMessage(language, key, capitalize = true) {
    let message;
    switch (language) {
        case Language.English:
            message = english[key];
            break;
        case Language.Nederlands:
            message = dutch[key];
            break;
    }
    if (capitalize) {
        return `${message[0].toUpperCase()}${message.substr(1)}`;
    }
    return message;
}
export function getTemplate(locale, key, capitalize = true, ...values) {
    let template;
    switch (locale.language) {
        case Language.English:
            template = english[key];
            break;
        case Language.Nederlands:
            template = dutch[key];
            break;
    }
    for (let i = 0; i < values.length; i++) {
        let value;
        if (typeof values[i] === "string") {
            value = values[i];
        }
        else {
            value = values[i](locale);
        }
        template = template.replace(`{${i}}`, value);
    }
    if (capitalize) {
        return `${template[0].toUpperCase()}${template.substr(1)}`;
    }
    return template;
}
export function formatMoney(money, { language, decimalSeparator, thousandsSeparator, }) {
    const decimalNumbers = money[1] - Math.floor(money[1] / 100) * 100;
    let wholeNumbers = `${Math.floor((money[1] - decimalNumbers) / 100)}`;
    let index = wholeNumbers.length - 3;
    while (index > 0) {
        wholeNumbers = `${wholeNumbers.substr(0, index)}${thousandsSeparator}${wholeNumbers.substr(index)}`;
        index -= 3;
    }
    const formattedWholeNumber = `${wholeNumbers}${decimalSeparator}${decimalNumbers < 10 ? "0" + decimalNumbers : decimalNumbers}`;
    return `${formattedWholeNumber} ${getCurrency(language, money[0])}`;
}
export function formatPercentage(percentage, { decimalSeparator }) {
    return `${percentage * 100}%`.replace(".", decimalSeparator);
}
export function formatMoneyHTML(money, { language, decimalSeparator, thousandsSeparator, currencySymbolClass, numberClass, currencyCodeClass, }) {
    const decimalNumbers = money[1] - Math.floor(money[1] / 100) * 100;
    let wholeNumbers = `${Math.floor((money[1] - decimalNumbers) / 100)}`;
    let index = wholeNumbers.length - 3;
    while (index > 0) {
        wholeNumbers = `${wholeNumbers.substr(0, index)}${thousandsSeparator}${wholeNumbers.substr(index)}`;
        index -= 3;
    }
    const formattedWholeNumber = `${wholeNumbers}${decimalSeparator}${decimalNumbers < 10 ? "0" + decimalNumbers : decimalNumbers}`;
    return `<span class="${currencySymbolClass}">${getCurrencySymbol(money[0])}</span><span class="${numberClass}">${formattedWholeNumber}</span><span class="${currencyCodeClass}">${getCurrency(language, money[0])}</span>`;
}
export function getLanguage(language, selectedLanguage) {
    switch (selectedLanguage) {
        case Language.English:
            return getMessage(language, "language.english");
        case Language.Nederlands:
            return getMessage(language, "language.dutch");
        default:
            return getMessage(language, "language.english");
    }
}
export function getBrokerName(language, brokerName) {
    switch (brokerName) {
        case BrokerName.Argenta:
            return getMessage(language, "broker.argenta");
        case BrokerName.Belfius:
            return getMessage(language, "broker.belfius");
        case BrokerName.BinckBank:
            return getMessage(language, "broker.binckbank");
        case BrokerName.BNPParibasFortis:
            return getMessage(language, "broker.bnpparibasfortis");
        case BrokerName.Bolero:
            return getMessage(language, "broker.bolero");
        case BrokerName.DEGIRO:
            return getMessage(language, "broker.degiro");
        case BrokerName.DeutscheBank:
            return getMessage(language, "broker.deutschebank");
        case BrokerName.Keytrade:
            return getMessage(language, "broker.keytrade");
        case BrokerName.LYNX:
            return getMessage(language, "broker.lynx");
    }
}
export function getSecurityType(language, securityType) {
    switch (securityType) {
        case SecurityType.Share:
            return getMessage(language, "security.share");
        case SecurityType.ETF:
            return getMessage(language, "security.etf");
        case SecurityType.Option:
            return getMessage(language, "security.option");
        case SecurityType.Bond:
            return getMessage(language, "security.bond");
    }
}
export function getExchange(language, exchange) {
    switch (exchange) {
        case Exchange.NYSE:
            return getMessage(language, "exchange.nyse");
        case Exchange.Nasdaq:
            return getMessage(language, "exchange.nasdaq");
        case Exchange.JapanExchangeGroup:
            return getMessage(language, "exchange.japanexchangegroup");
        case Exchange.LondonStockExchange:
            return getMessage(language, "exchange.londonstockexchange");
        case Exchange.ShanghaiStockExchange:
            return getMessage(language, "exchange.shanghaistockexchange");
        case Exchange.HongKongStockExchange:
            return getMessage(language, "exchange.hongkongstockexchange");
        case Exchange.EuronextAmsterdam:
            return getMessage(language, "exchange.euronextamsterdam");
        case Exchange.EuronextBrussels:
            return getMessage(language, "exchange.euronextbrussels");
        case Exchange.EuronextDublin:
            return getMessage(language, "exchange.euronextdublin");
        case Exchange.EuronextLisbon:
            return getMessage(language, "exchange.euronextlisbon");
        case Exchange.EuronextOslo:
            return getMessage(language, "exchange.euronextoslo");
        case Exchange.EuronextParis:
            return getMessage(language, "exchange.euronextparis");
        case Exchange.TorontoStockExchange:
            return getMessage(language, "exchange.torontostockexchange");
        case Exchange.ShenzhenStockExchange:
            return getMessage(language, "exchange.shenzhenstockexchange");
        case Exchange.BombayStockExchange:
            return getMessage(language, "exchange.bombaystockexchange");
        case Exchange.IndiaNationalStockExchange:
            return getMessage(language, "exchange.indianationalstockexchange");
        case Exchange.DeutscheBorse:
            return getMessage(language, "exchange.deutscheborse");
        case Exchange.SIXSwissExchange:
            return getMessage(language, "exchange.sixswissexchange");
        case Exchange.KoreaExchange:
            return getMessage(language, "exchange.koreaexchange");
    }
}
export function getCurrency(language, currencyType) {
    switch (currencyType) {
        case CurrencyType.EUR:
            return getMessage(language, "currency.eur.short");
        case CurrencyType.GBP:
            return getMessage(language, "currency.gbp.short");
        case CurrencyType.USD:
            return getMessage(language, "currency.usd.short");
    }
}
export function getTransactionType(language, transactionType) {
    switch (transactionType) {
        case TransactionType.Buy:
            return getMessage(language, "transaction.buy");
        case TransactionType.Sell:
            return getMessage(language, "transaction.sell");
    }
}
export function getActionType(language, actionType) {
    switch (actionType) {
        case ActionType.Transaction:
            return getMessage(language, "action.transaction");
        case ActionType.Withdrawal:
            return getMessage(language, "action.withdrawal");
        case ActionType.OpenAccount:
            return getMessage(language, "action.openaccount");
        case ActionType.Deposit:
            return getMessage(language, "action.deposit");
        case ActionType.PaidDividend:
            return getMessage(language, "action.paiddividend");
    }
}

import { largerThan, lessThan } from "../Money";
export var SecurityType;
(function (SecurityType) {
    SecurityType[SecurityType["Share"] = 0] = "Share";
    SecurityType[SecurityType["ETF"] = 1] = "ETF";
    SecurityType[SecurityType["Option"] = 2] = "Option";
    SecurityType[SecurityType["Bond"] = 3] = "Bond";
})(SecurityType || (SecurityType = {}));
export var TransactionType;
(function (TransactionType) {
    TransactionType[TransactionType["Buy"] = 0] = "Buy";
    TransactionType[TransactionType["Sell"] = 1] = "Sell";
})(TransactionType || (TransactionType = {}));
export const transactionTypes = [
    TransactionType.Buy,
    TransactionType.Sell,
];
export const securityTypes = [
    SecurityType.Share,
    SecurityType.ETF,
    SecurityType.Option,
    SecurityType.Bond,
];
export var Exchange;
(function (Exchange) {
    Exchange[Exchange["NYSE"] = 0] = "NYSE";
    Exchange[Exchange["Nasdaq"] = 1] = "Nasdaq";
    Exchange[Exchange["NasdaqHelsinki"] = 2] = "NasdaqHelsinki";
    Exchange[Exchange["NasdaqStockholm"] = 3] = "NasdaqStockholm";
    Exchange[Exchange["NasdaqCopenhagen"] = 4] = "NasdaqCopenhagen";
    Exchange[Exchange["JapanExchangeGroup"] = 5] = "JapanExchangeGroup";
    Exchange[Exchange["LuxembourgStockExchange"] = 6] = "LuxembourgStockExchange";
    Exchange[Exchange["LondonStockExchange"] = 7] = "LondonStockExchange";
    Exchange[Exchange["ShanghaiStockExchange"] = 8] = "ShanghaiStockExchange";
    Exchange[Exchange["HongKongStockExchange"] = 9] = "HongKongStockExchange";
    Exchange[Exchange["EuronextAmsterdam"] = 10] = "EuronextAmsterdam";
    Exchange[Exchange["EuronextBrussels"] = 11] = "EuronextBrussels";
    Exchange[Exchange["EuronextDublin"] = 12] = "EuronextDublin";
    Exchange[Exchange["EuronextLisbon"] = 13] = "EuronextLisbon";
    Exchange[Exchange["EuronextOslo"] = 14] = "EuronextOslo";
    Exchange[Exchange["EuronextParis"] = 15] = "EuronextParis";
    Exchange[Exchange["TorontoStockExchange"] = 16] = "TorontoStockExchange";
    Exchange[Exchange["ShenzhenStockExchange"] = 17] = "ShenzhenStockExchange";
    Exchange[Exchange["BombayStockExchange"] = 18] = "BombayStockExchange";
    Exchange[Exchange["IndiaNationalStockExchange"] = 19] = "IndiaNationalStockExchange";
    Exchange[Exchange["ViennaStockExchange"] = 20] = "ViennaStockExchange";
    Exchange[Exchange["AthensStockExchange"] = 21] = "AthensStockExchange";
    Exchange[Exchange["JohannesburgStockExchange"] = 22] = "JohannesburgStockExchange";
    Exchange[Exchange["NationalStockExchangeOfAustralia"] = 23] = "NationalStockExchangeOfAustralia";
    Exchange[Exchange["AustralianStockExchange"] = 24] = "AustralianStockExchange";
    Exchange[Exchange["SingaporeStockExchange"] = 25] = "SingaporeStockExchange";
    Exchange[Exchange["BolsaDeMadrid"] = 26] = "BolsaDeMadrid";
    Exchange[Exchange["TokyoStockExchange"] = 27] = "TokyoStockExchange";
    Exchange[Exchange["BorsaItaliana"] = 28] = "BorsaItaliana";
    Exchange[Exchange["DeutscheBorse"] = 29] = "DeutscheBorse";
    Exchange[Exchange["SIXSwissExchange"] = 30] = "SIXSwissExchange";
    Exchange[Exchange["KoreaExchange"] = 31] = "KoreaExchange";
})(Exchange || (Exchange = {}));
export const exchanges = [
    Exchange.NYSE,
    Exchange.Nasdaq,
    Exchange.JapanExchangeGroup,
    Exchange.LondonStockExchange,
    Exchange.ShanghaiStockExchange,
    Exchange.HongKongStockExchange,
    Exchange.EuronextAmsterdam,
    Exchange.EuronextBrussels,
    Exchange.EuronextDublin,
    Exchange.EuronextLisbon,
    Exchange.EuronextOslo,
    Exchange.EuronextParis,
    Exchange.TorontoStockExchange,
    Exchange.ShenzhenStockExchange,
    Exchange.BombayStockExchange,
    Exchange.IndiaNationalStockExchange,
    Exchange.DeutscheBorse,
    Exchange.SIXSwissExchange,
    Exchange.KoreaExchange,
];
export function calculateIntervalFees(transaction, intervals) {
    const fees = [];
    for (const interval of intervals) {
        if (largerThan(transaction.value, interval.openFrom)) {
            if (interval.closedTo !== undefined) {
                if (largerThan(transaction.value, interval.closedTo)) {
                    continue;
                }
            }
            if (interval.calculateFee === undefined) {
                if (interval.minimumFee === undefined) {
                    throw new Error("minimumFee cannot be undefined while calculateFee is undefined");
                }
                fees.push(interval.minimumFee);
                break;
            }
            const calculatedFee = interval.calculateFee(transaction.value);
            const calculatedFeeMoney = calculatedFee.value;
            if (interval.minimumFee !== undefined && lessThan(calculatedFeeMoney, interval.minimumFee.value)) {
                fees.push(interval.minimumFee);
                break;
            }
            if (interval.maximumFee !== undefined && largerThan(calculatedFeeMoney, interval.maximumFee.value)) {
                fees.push(interval.maximumFee);
                break;
            }
            fees.push(calculatedFee);
            break;
        }
    }
    return fees;
}

import { writable } from "svelte/store";
import { CurrencyType } from "./calc/Money";
import { Exchange, SecurityType, TransactionType } from "./calc/action/Transaction";
import { ActionType } from "./calc/action/ActionType";
import { Bolero } from "./calc/broker/Bolero";
import { Language } from "./i18n/language";
import { BNPParibasFortis } from "./calc/broker/BNPParibasFortis";
import { Argenta } from "./calc/broker/Argenta";
export const brokers = writable([
    new Bolero(),
    new BNPParibasFortis(),
    new Argenta(),
]);
export const lang = writable(Language.English);
export const currency = writable(CurrencyType.EUR);
export const decimalSeparator = writable(",");
export const thousandsSeparator = writable(" ");
let actionNumber = 0;
export const actionNumbers = writable(new Map());
function createActionsStore() {
    const { subscribe, set, update } = writable([]);
    let updateLocalStorage = true;
    // Why delay?
    // localStorage.setItem is blocking, and could slow down the UI quite drastically if it is called often
    // while the user is still typing
    let previousTimeoutId;
    function delayedLocalStorageUpdate(actions) {
        if (!updateLocalStorage) {
            return;
        }
        if (previousTimeoutId !== undefined) {
            clearTimeout(previousTimeoutId);
        }
        previousTimeoutId = setTimeout(() => {
            window.localStorage.setItem("actions", JSON.stringify(actions));
        }, 2500);
    }
    const store = {
        subscribe,
        set,
        update,
        change: (action, newProperties) => {
            update(actions => {
                Object.assign(action, newProperties);
                delayedLocalStorageUpdate(actions);
                return actions;
            });
        },
        add: (action) => {
            update(actions => {
                actions.push(action);
                actionNumbers.update(map => {
                    map.set(action, ++actionNumber);
                    return map;
                });
                delayedLocalStorageUpdate(actions);
                return actions;
            });
        },
        addMultiple: (actions) => {
            update(storeActions => {
                storeActions.push(...actions);
                actionNumbers.update(map => {
                    for (const action of actions) {
                        map.set(action, ++actionNumber);
                    }
                    return map;
                });
                delayedLocalStorageUpdate(storeActions);
                return storeActions;
            });
        },
        remove: (action) => {
            update(actions => {
                actions.splice(actions.indexOf(action), 1);
                actionNumbers.update(map => {
                    map.delete(action);
                    actionNumber = 0;
                    for (const [action] of map) {
                        map.set(action, ++actionNumber);
                    }
                    return map;
                });
                delayedLocalStorageUpdate(actions);
                return actions;
            });
        },
    };
    const urlSearchParams = new URLSearchParams(window.location.search);
    const data = urlSearchParams.get("d");
    if (data !== null) {
        updateLocalStorage = false;
        store.addMultiple(JSON.parse(atob(data)));
    }
    else {
        const localStorageActionsJson = window.localStorage.getItem("actions");
        if (localStorageActionsJson !== null) {
            store.addMultiple(JSON.parse(localStorageActionsJson));
        }
        else {
            const defaultActions = [
                {
                    type: ActionType.Transaction,
                    startTime: 0,
                    frequency: 1,
                    exchange: Exchange.EuronextAmsterdam,
                    transactionType: TransactionType.Buy,
                    security: {
                        type: SecurityType.Share,
                    },
                    quantity: 150,
                    value: [CurrencyType.EUR, 150000],
                },
                {
                    type: ActionType.Transaction,
                    startTime: 0,
                    frequency: 1,
                    exchange: Exchange.EuronextAmsterdam,
                    transactionType: TransactionType.Buy,
                    security: {
                        type: SecurityType.Share,
                    },
                    quantity: 1,
                    value: [CurrencyType.EUR, 100],
                },
                {
                    type: ActionType.Transaction,
                    startTime: 0,
                    frequency: 1,
                    exchange: Exchange.NYSE,
                    transactionType: TransactionType.Buy,
                    security: {
                        type: SecurityType.Share,
                    },
                    quantity: 225,
                    value: [CurrencyType.USD, 275000],
                },
                {
                    type: ActionType.Transaction,
                    startTime: 0,
                    frequency: 1,
                    exchange: Exchange.LondonStockExchange,
                    transactionType: TransactionType.Buy,
                    security: {
                        type: SecurityType.Share,
                    },
                    quantity: 400,
                    value: [CurrencyType.GBP, 1500000],
                },
                {
                    type: ActionType.Transaction,
                    startTime: 0,
                    frequency: 1,
                    exchange: Exchange.EuronextBrussels,
                    transactionType: TransactionType.Buy,
                    security: {
                        type: SecurityType.Share,
                    },
                    quantity: 200,
                    value: [CurrencyType.EUR, 700000],
                },
            ];
            store.addMultiple(defaultActions);
        }
    }
    return store;
}
export const actions = createActionsStore();

import { getMessage, getTemplate } from "../i18n/language";
export class Fee {
    constructor(action, value, typeKey, calculationDescriptionKey, calculationDescriptionValues) {
        this.action = action;
        this.value = value;
        this.typeKey = typeKey;
        this.calculationDescriptionKey = calculationDescriptionKey;
        this.calculationDescriptionValues = calculationDescriptionValues;
    }
    getType(language) {
        return getMessage(language, this.typeKey);
    }
    getCalculationDescription(locale, capitalize = true) {
        return getTemplate(locale, this.calculationDescriptionKey, capitalize, ...this.calculationDescriptionValues);
    }
}

export var CurrencyType;
(function (CurrencyType) {
    CurrencyType[CurrencyType["USD"] = 0] = "USD";
    CurrencyType[CurrencyType["GBP"] = 1] = "GBP";
    CurrencyType[CurrencyType["EUR"] = 2] = "EUR";
})(CurrencyType || (CurrencyType = {}));
export const currencyTypes = [
    CurrencyType.USD,
    CurrencyType.GBP,
    CurrencyType.EUR,
];
export function convertCurrency(money, toCurrency) {
    let exchangeRate = 1;
    switch (money[0]) {
        case CurrencyType.EUR:
            if (toCurrency === CurrencyType.USD) {
                exchangeRate = 1.161;
            }
            else if (toCurrency === CurrencyType.GBP) {
                exchangeRate = 0.855;
            }
            break;
        case CurrencyType.GBP:
            if (toCurrency === CurrencyType.USD) {
                exchangeRate = 1.359;
            }
            else if (toCurrency === CurrencyType.EUR) {
                exchangeRate = 1.170;
            }
            break;
        case CurrencyType.USD:
            if (toCurrency === CurrencyType.GBP) {
                exchangeRate = 0.736;
            }
            else if (toCurrency === CurrencyType.EUR) {
                exchangeRate = 0.861;
            }
            break;
    }
    return Math.round(money[1] * exchangeRate);
}
export function lessThanOrEqual(first, second) {
    return convertCurrency(first, second[0]) <= second[1];
}
export function largerThanOrEquals(first, second) {
    return convertCurrency(first, second[0]) >= second[1];
}
export function lessThan(first, second) {
    return convertCurrency(first, second[0]) < second[1];
}
export function largerThan(first, second) {
    return convertCurrency(first, second[0]) > second[1];
}
export function add(first, second) {
    const sum = [...first];
    sum[1] += convertCurrency(second, first[0]);
    return sum;
}

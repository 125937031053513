import { CurrencyType } from "./calc/Money";
export function getCurrencySymbol(currency) {
    switch (currency) {
        case CurrencyType.EUR:
            return "€";
        case CurrencyType.GBP:
            return "£";
        case CurrencyType.USD:
            return "$";
    }
}
